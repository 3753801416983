.exercises {
    text-align: left;
    margin: -60px -20px;
    padding: 1em;
}

.exercises h1 {
    text-align: center;
    margin-bottom: 1em;
}

.exercises h3:hover {
    color: #a7a7a7;
    text-decoration: underline;
}

/* Solid border */
hr.solid {
    border-top: 1px solid #bbb;
    margin-bottom: 1em;
  }