.homepage {
    display: flex;
    flex-direction: column;
}

.blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home-block {
    width: 92vw;
    height: 20em;
    border-radius: 1em;
    background-color: #EEEEEE;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 2em;
    padding-top: 1em;
    box-shadow: 2px 10px 8px grey;
}

#block-button {
    height: 4em;
    border-radius: 0 0 1em 1em;
    background-color: #007b60;
    border: none;
}

@media (min-width: 800px) {
    .homepage {
        flex-direction: row;
    }

    .blocks {
        flex-direction: row;
        justify-content: space-between;
    }

    .home-block {
        width: 30vw;
        margin: 0 1vw;
    }
}