.module-container {
    padding-bottom: 2em;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border: solid 2px #eeeeee;
    box-shadow: 2px 10px 8px #dddddd;
    margin: 1.5em 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  #page-header {
      background-image: linear-gradient(to bottom, #007b60, 90%, #ffffff);
      background-color: #007b60;
      width: 100vw;
      text-decoration: none;
      text-align: left;
      padding-top: 1em;
      padding-bottom: 8em;
      color: #eeeeee;
      margin: -60px -20px;
  }

  #page-header p {
      color: #cecccc;
      font-size: 60%;
  }

  .module-points {
      padding: 0 2em;
      list-style-type: disc;
  }

  .module-points-header {
      padding: 0 1em;
  }

  .arrows {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 2em 0;
  }

  .arrow-btn {
      font-weight: 600;
      width: 50vw;
      color: black;
      font-size: 150%;
      margin: 0 auto;
  }

  .menu-container {
      border: solid 1px #eeeeee;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 0.5em;
      box-shadow: 2px 10px 8px #dddddd;
      margin-bottom: 3em;
  }

  .manual-container {
    border: solid 1px #eeeeee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    padding: 0.5em;
    box-shadow: 2px 10px 8px #dddddd;
    margin-bottom: 1em;
}

  .exercises-container {
    border: solid 1px #eeeeee;
    display: flex;
    background-color: white;
    flex-direction: column;
    text-align: left;
    padding: 0.8em;
    box-shadow: 2px 10px 8px #dddddd;
    margin-bottom: 3em;
  }

  @media (min-width: 900px) {
    #page-header {
        background-image: linear-gradient(to bottom, #007b60, 90%, #ffffff);
        background-color: #007b60;
        color: #eeeeee;
        width: 100vw;
    }

    .module-background {
        width: 80vw;
        margin: 0vw 0vw 0vw 1vw;
        padding: 0 1.5vw;
    }

    .module-container {
        display: grid;
    }

    .video-responsive {
        padding-bottom: 10px;
        height: 450px;
        width: 800px;
      }

    .video-responsive iframe {
        height: 100%;
        width: 100%;
      }

    .arrows {
        width: 400px;
    }
    
    .menu-container {
        width: 400px;
        background-color: white;
        margin: 1.5em 0em;
    }

    .manual-container {
        width: 400px;
        background-color: white;
        margin: 1.5em 0em;
    }

    .body-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .menu-arrow-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: right;
    }
  }